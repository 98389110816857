<template>
  <div class="home">
    <div class="container">
      <v-header />
    </div>
    <div class="section dt9 db7 mt7 mb7">
      <div class="container">
        <div class="row middle">
          <div class="d-6">
            <h1 class="db9">Мы&nbsp;получили вашу заявку</h1>
            <p class="h4">
              <strong
                >В&nbsp;ближайшее время с&nbsp;вами свяжется менеджер и
                расскажет, что делать дальше.</strong
              >
            </p>
            <p>Если у&nbsp;вас остались вопросы о&nbsp;наших сервисах, предлагаем перейти на&nbsp;<a href="/">главную страницу</a> сайта</p>
          </div>
          <div class="d-5 d-offset-1 m-hide middle">
            <img src="@/assets/success.svg"
              data-copyright-source="https://storyset.com/illustration/playful-cat/rafiki"
              data-copyright-name="Storyset"
              data-copyright-owner="Storyset"
              data-copyright-profile="https://storyset.com/"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const meta = {
  title: "В ближайшее время с вами свяжется менеджер и расскажет, что делать дальше | WorkCat",
};

export default {
  name: "Success",
  components: {
    "v-header": () => import("@/components/v-header.vue"),
  },
  metaInfo: {
    title: meta.title,
    meta: [
      {
        name: "og:title",
        content: meta.title,
      },
      {
        name: "robots",
        content: "none",
      },
    ],
  },
};
</script>
